import 'foundation-sites-5/js/foundation/foundation.js';
import 'foundation-sites-5/js/foundation/foundation.abide.js';
import 'foundation-sites-5/js/foundation/foundation.equalizer.js';
import 'foundation-sites-5/js/foundation/foundation.reveal.js';
import 'foundation-sites-5/js/foundation/foundation.topbar.js';
import 'foundation-sites-5/js/foundation/foundation.dropdown.js';
// import 'spin.js/spin.min.js';
import { Spinner } from 'spin.js';
import 'sweetalert/dist/sweetalert.min.js';
import 'zurbfoundation5-multiselect/zmultiselect/zurb5-multiselect.js';

window.Spinner = Spinner;
